.navbar {
    display: flex;
    justify-content: space-around;
    padding: 16px;
    box-shadow: 0 1px 3px -2px black;
    align-items: center;
}

.nav-logo {
    display: flex;
    align-items: center;
    gap: 10px
}

.nav-logo p {
    color: #171717;
    font-size: 45px;
    font-weight: 600;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 60px;
    color: #626262;
    font-size: 25px;
    font-weight: 500;
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: -5px;
    cursor: pointer
}

.nav-menu li hr {
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #FF4141;
}

.nav-login-cart {
    display: flex;
    align-items: center;
    gap: 45px;
}

.nav-login-cart button {
    width: 157px;
    height: 40px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    background: white;
    cursor: pointer;
}

.nav-login-cart button:active {
    background: #f3f3f3;
}

.nav-cart-count {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    margin-left: -55px;
    border-radius: 11px;
    font-size: 14px;
    background: red;
    color: white;
}

.noUnderline {
    text-decoration: none;
    color: #626262;
}


@media(max-width: 1280px) {
    .navbar {
        padding: 12px 50px;
    }

    .nav-logo img {
        width: 40px;
    }

    .nav-logo p {
        font-size: 25px;
    }

    .nav-menu {
        gap: 30px;
        font-size: 16px;
        width: 100%;  
    }

    .nav-login-cart {
        gap: 30px;
    }

    .nav-login-cart button {
        width: 120px;
        height: 45px;
        font-size: 16px;
    }

    .nav-cart-count {
        margin-left: -40px;
        font-size: 12px;
    }
}

@media(max-width:1024px) {
    .navbar {
        padding: 12px 30px;
    }

    .nav-menu {
        gap: 25px;
        font-size: 14px;
    }

    .nav-login-cart button {
        width: 80px;
        height: 35px;
        font-size: 14px;
    }

    .nav-login-cart img {
        width: 30px;
    }

    .nav-cart-count {
        width: 18px;
        height: 18px;
    }

}

.nav-dropdown {
    display: none;
}

@media(max-width:800px) {
    .navbar {
        padding: 10px 0px;
    }

    .nav-dropdown {
        display: block;
        width: 30px;
        rotate: -90deg;
        transition: 0.5px;
    }
.nav-menu{
    display: none;
    height: 80px;
    height: 100px;
    position: absolute;
    justify-content: center;
    background: white;
    top: 50px;
}

.nav-menu-visible{
    display: flex;
}

.nav-dropdown.open{
    transform: rotate(90deg);
}
}


@media(max-width:500px) {
    .navbar {
        padding: 8px 0px;
        gap: 0;
    }

    .nav-logo{
        transform: scale(0.8);
    }
    .nav-menu{
        height: 70px;
        top: 50px;
    }
    .nav-login-cart{
        transform: scale(0.8);
    }
}
