.new-collections{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
    }
    
    .new-collections h1{
        color: #171717;
        font-size: 50px;
        font-weight: 600;
    }
    
    .new-collections hr{
        width: 200px;
        height: 6px;
        border-radius: 10px;
        background: #252525;
    }
    
    .collections{
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 30px;
    }
    

    @media(max-width:1280px) {
     
    
        .new-collections h1 {
            font-size: 40px;
        }
    
        .new-collections hr {
            width: 160px;
            height: 4px;
        }
    
        .collections {
            gap: 20px;
            margin-top: 30px;
        }
    }
    
    @media(max-width:1024px) {
     
    
        .new-collections h1 {
            font-size: 30px;
        }
    
        .new-collections hr {
            width: 120px;
            height: 3px;
        }
    
        .collections {
            gap: 15px;
            margin-top: 20px;
        }
    }
    
    @media(max-width:800px) {
        .new-collections {
            gap: 6px;
        }
    
        .new-collections h1 {
            font-size: 20px;
        }
    
        .new-collections hr {
            width: 100px;
        }
    
        .collections {
            gap: 5px;
        }
    }
    
    @media(max-width:500px) {
      
        .collections {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
    }